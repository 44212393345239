import { Component, EventEmitter, Injector, Output, ViewChild, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    UserServiceProxy,
    TenantServiceProxy,
    ProfileServiceProxy,
    PagedResultDtoOfUserListDto,
    UserListDto
} from '@shared/service-proxies/service-proxies';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';

@Component({
    selector: 'gdprOnboardInfoModal',
    styleUrls: ['./gdpr-onboardinfo-modal.component.less'],
    templateUrl: './gdpr-onboardinfo-modal.component.html'
})
export class GDPROnboardInfoModalComponent extends AppComponentBase implements OnInit {


    //Filters
    public allTenantAdmins: PagedResultDtoOfUserListDto;
    advancedFiltersAreShown = false;
    filterText = '';
    selectedPermission = '';
    role = '6';

    @ViewChild('gdprOnboardInfoModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    public isLocked: boolean = false;
    public isUserTenantAdmin: boolean = false; //indicate if user can approve GDPR documents himselfe
    tenantAdminUserList: UserListDto[];

    constructor(
        injector: Injector,
        private _tenantService: TenantServiceProxy,
        private _authService: AppAuthService,
        private _userServiceProxy: UserServiceProxy,
        private _profileService: ProfileServiceProxy,
        private router: Router
    ) {
        super(injector);
        this.filterText = '';
    }

    ngOnInit(): void {

    }

    show(isTenantLocked:boolean ): void {
        this.active = true;
        this.isLocked = isTenantLocked; //possiblu show other info/layout when tenant already is locked

        this._userServiceProxy.isAdmin().subscribe(isAdmin => {
            this.isUserTenantAdmin = isAdmin;
            this.modal.show();
        });



    }


    onShown(): void {
        //??
    }

    close(linkPath?: string) {
        this.active = false;
        this.modal.hide();
        if (linkPath) {
          this.router.navigate([linkPath]);
        }
      }

    logout(): void {
        this._authService.logout();
    }

}
