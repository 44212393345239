import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TenantLoginInfoDto, EditionPaymentType, SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';

export class ThemesLayoutBaseComponent extends AppComponentBase {

    tenant: TenantLoginInfoDto = new TenantLoginInfoDto();
    subscriptionStartType = SubscriptionStartType;
    editionPaymentType: typeof EditionPaymentType = EditionPaymentType;
    installationMode = true;

    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';

    userMenuToggleOptions: ToggleOptions = {
        target: 'body',
        targetState: 'kt-header__topbar--mobile-on',
        togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
    };

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 && (this.appSession.tenant.isInTrialPeriod || this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    getSubscriptionExpiringDayCount(): number {
        if (!this.appSession.tenant.subscriptionEndDateUtc) {
            return 0;
        }

        return Math.round(moment.utc(this.appSession.tenant.subscriptionEndDateUtc).diff(moment().utc(), 'days', true));
    }


    getTrialSubscriptionNotification(): string {
        return this.l(
            'TrialSubscriptionNotification',
            `<strong>${this.appSession.tenant.edition.displayName}</strong>`,
            `<a href="/account/buy?editionPaymentType=${this.editionPaymentType.BuyNow}&editionId=${this.appSession.tenant.edition.id}&tenantId=${this.appSession.tenant.id}">${this.l('ClickHere')}</a>`
        );
    }

    getExpireNotification(localizationKey: string): string {
        return this.l(localizationKey, this.getSubscriptionExpiringDayCount());
    }


    showGDPRApprovalReminder(): boolean {
        if (this.appSession.tenantId != null) //Check if host-tenant
        {
            if(window.location.pathname.includes("subscription-management"))
            {
                return false;
            }
            else
            {
                return (!this.appSession.tenant.gdprCompletionTime);
            }
        }
        else
            return false; //Never show GDPR for host
    }

    showSkolonButton() : boolean {
        if (this.appSession && this.appSession.tenant && this.appSession.tenant.skolonSchoolId !== undefined) {
            return this.appSession.tenant.skolonSchoolId !== null && this.appSession.tenant.skolonSchoolId !== '';
        } else {
            return false;
        }
    }

    checkLockAndEnforceGDPRApproval(): boolean {
        if (this.appSession.tenantId != null) //Check if host-tenant
            return (!this.appSession.tenant.gdprCompletionTime) && (this.showGDPRAgreementReminderLeftDayCount() == 0);
        else
            return false; //Never show GDPR for host
    }

    showGDPRAgreementReminderLeftDayCount(): number {
        if (this.appSession.tenantId == null) //Check if host-tenant
            return 99;

        if (!this.appSession.tenant.gdprReminderStartTime) {
            return AppConsts.gdprApprovalReminderDayCount;
        } else {
            var count = AppConsts.gdprApprovalReminderDayCount + Math.round(moment.utc(this.appSession.tenant.gdprReminderStartTime).diff(moment().utc(), 'days', true));
            if (count <= 0) {
                count = 0;
            }
            return count;
        }
    }

    getGDPRLockNotificationText(): string {
        return this.l(
            'GDPRAgreementLockedNotification',
        );
    }

    getGDPRReminderNotificationText(): string {
        return this.l(
            'GDPRAgreementNeededNotification',
            `<strong>${this.showGDPRAgreementReminderLeftDayCount().toString()}</strong>`
        );
    }






}
