import { Injector, Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import * as _ from 'lodash';
import { ShepherdService } from 'angular-shepherd';
import { AppComponentBase } from '@shared/common/app-component-base';





export const defaultStepOptions = {
    classes: 'shepherd',
    scrollTo: true,
    cancelIcon: {
        enabled: true
    }
};



@Component({
    selector: 'tourguide',
    encapsulation: ViewEncapsulation.None
})
export class TourGuideComponent extends AppComponentBase implements OnInit, AfterViewInit {
    href: string = null;
    builtInButtons = {
        cancel: {
            classes: 'cancel-button',
            secondary: true,
            text: this.l('Exit'),
            type: 'cancel'
        },
        next: {
            classes: 'next-button',
            text: this.l('Next'),
            type: 'next'
        },
        back: {
            classes: 'back-button',
            secondary: true,
            text: this.l('BackButton'),
            type: 'back'
        },
        complete: {
            classes: 'complete-button',
            secondary: true,
            text: this.l('Complete'),
            type: 'next'
        }

    };

    constructor(injector: Injector, public shepherdService: ShepherdService, private router: Router) {
        super(injector);
    }

    ngAfterViewInit(): void {
        this.shepherdService.defaultStepOptions = defaultStepOptions;
        this.shepherdService.modal = true;
        this.shepherdService.confirmCancel = false;
    }

    public timeout() {
        return setTimeout(() => {
            this.shepherdService.next();
        }, 250);
    }
    public timeoutLong() {
        return setTimeout(() => {
            this.shepherdService.next();
        }, 1000);
    }
    public timeoutExtraLong() {
        return setTimeout(() => {
            this.shepherdService.next();
        }, 1500);
    }
    public cancelTour() {
        if (this.shepherdService.tourObject != null) {
            this.shepherdService.cancel();
        }
       
    }

    

    startTour() {
        this.shepherdService.addSteps([]);
        
        this.href = this.router.url;

        if (this.href.indexOf('organization-units') > 0) {

            this.shepherdService.addSteps(
                [
                    {
                        attachTo: {
                            element: '#AddYear',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                        ],
                        classes: '',
                        id: 'AddYear',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ClickToAddYear'),
                        advanceOn: { selector: '#AddYear', event: 'click' },  
                    },
                    {
                        attachTo: {
                            element: '#AddYear',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'Dummy',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ClickToAddYear'),

                        advanceOn: { selector: '#AddYear', event: 'click' },
                        when: {
                            show: () => {

                                this.timeout();

                            }

                        },

                    },
                    {
                        attachTo: {
                            element: '#OrganizationUnitDisplayName',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'OrganizationUnitDisplayName',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('FillInNameDescriptionAndSave'),
                        advanceOn: { selector: '#SaveYear', event: 'click' },
                    },


                    {
                        attachTo: {
                            element: '#OrganizationTreeBody',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'AddGroupOrClass',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('AddGroupOrClassDescription'),

                    },

                    {
                        attachTo: {
                            element: '#AddNewGroupClass',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'AddNewGroupClass',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('AddNewGroupClassDescription'),
                        advanceOn: { selector: '#SaveYear', event: 'click' },

                    },
                    {
                        attachTo: {
                            element: '#OrganizationTreeBody',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ViewClass',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ViewClassDescription'),

                    },
                    {
                        attachTo: {
                            element: '#OuMembersTable',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ViewClass',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ViewClassAndAddMemberDescription'),
                        advanceOn: { selector: '#AddMember', event: 'click' },

                    },
                    {
                        attachTo: {
                            element: '#OuMembersTable',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ViewClass',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ViewClassAndAddMemberDescription'),
                        when: {
                            show: () => {

                                this.timeout();

                            }

                        },

                    },

                    {
                        attachTo: {
                            element: '#Users',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'AddNewGroupClass',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('AddMembersDescription'),
                        advanceOn: { selector: '#SaveUsers', event: 'click' },

                    },
                    {
                        attachTo: {
                            element: '#Users',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'AddNewGroupClass',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('AddMembersDescription'),
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },

                    },

                    {
                        attachTo: {
                            element: '#DeleteUserFromClass',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.complete
                        ],
                        classes: '',
                        id: 'RemoveUsers',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('RemoveUsersDescription'),

                    },


                ]);

        }

        else if (this.href.indexOf('users') > 0) {

            this.shepherdService.addSteps(
                [

                    {
                        attachTo: {
                            element: '#CreateNewUser',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                        ],
                        classes: '',
                        id: 'CreateNewUser',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('CreateNewUserDescription') + '<br>' + '<br>' + this.l('ExcelImportUserDescription') + '<br>' + '<br>' + this.l('ManualUserRegistrationDescription') + '<br>' + '<br>' + this.l('CreateUserDeescription'),
                        advanceOn: { selector: '#CreateNewUser', event: 'click' },
                    },
                    {
                        attachTo: {
                            element: '#CreateNewUser',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                        ],
                        classes: '',
                        id: 'DummyCreateNewUser',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('CreateNewUserDescription'),

                        advanceOn: { selector: '#CreateNewUser', event: 'click' },
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },

                    },
                    {
                        attachTo: {
                            element: '#Name',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'Name',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('NameDescription'),
                        advanceOn: { selector: '#Name', event: 'key.enter' },
                    },

                    {
                        attachTo: {
                            element: '#Surname',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'Surname',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SurnameDescription'),
                    },

                    {
                        attachTo: {
                            element: '#EmailAddress',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'EmailAddress',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('EmailAddressDescription'),
                    },

                    {
                        attachTo: {
                            element: '#PhoneNumber',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'PhoneNumber',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('PhoneNumberDescription'),
                    },

                    {
                        attachTo: {
                            element: '#EditUser_SetRandomPasswordCheckBox',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'EditUser_SetRandomPassword',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('EditUser_SetRandomPasswordDescription'),
                    },

                    {
                        attachTo: {
                            element: '#EditUser_ShouldChangePasswordOnNextLoginCheckBox',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'EditUser_ShouldChangePasswordOnNextLogin',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('EditUser_ShouldChangePasswordOnNextLoginDescription'),
                    },

                    {
                        attachTo: {
                            element: '#EditUser_SendActivationEmailCheckBox',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'EditUser_SendActivationEmail',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('EditUser_SendActivationEmailDescription'),
                    },

                    {
                        attachTo: {
                            element: '#EditUser_IsActiveCheckBox',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'EditUser_IsActive',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('EditUser_IsActiveDescription'),
                    },

                    {
                        attachTo: {
                            element: '#EditUser_IsLockoutEnabledCheckBox',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'EditUser_IsLockoutEnabled',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('EditUser_IsLockoutEnabledDescription'),
                    },

                    {
                        attachTo: {
                            element: '#Roles-link',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                        ],
                        classes: '',
                        id: 'Roles',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('RolesDescription'),
                        advanceOn: { selector: '#Roles-link', event: 'click' },

                    },

                    {
                        attachTo: {
                            element: '#RolesToChoose',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'RolesToChoose',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('RolesToChooseDescription') + '<br>' + '<br>' + this.l('AdminRole') + '<br>' + '<br>' + this.l('UserRole') + '<br>' + '<br>' + this.l('AdministratorRole') + '<br>' + '<br>' + this.l('PedagogueRole'),
                    },

                    {
                        attachTo: {
                            element: '#OrganizationUnits-link',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back
                        ],
                        classes: '',
                        id: 'OrganizationUnits',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('OrganizationUnitsDescription'),
                        advanceOn: { selector: '#OrganizationUnits-link', event: 'click' },

                    },

                    {
                        attachTo: {
                            element: '#OrganizationUnitsTree',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back
                        ],
                        classes: '',
                        id: 'OrganizationUnitsTree',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('OrganizationUnitsTreeDescription') + '<br>' + '<br>' + this.l('StudentsAndPupilsOrganizationTreeDescription') + '<br>'+ '<br>' + this.l('OrganizationUnitsTreeDescriptionAndSave'),
                        advanceOn: { selector: '#SaveUser', event: 'click' },

                    },
                    {
                        attachTo: {
                            element: '#OrganizationUnitsTree',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back
                        ],
                        classes: '',
                        id: 'OrganizationUnitsTree',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('OrganizationUnitsTreeDescription') + '<br>' + '<br>' + this.l('StudentsAndPupilsOrganizationTreeDescription') + '<br>' + '<br>' + this.l('OrganizationUnitsTreeDescriptionAndSave'),
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },

                    },

                    {

                        attachTo: {
                            element: '#ImportUsersToGroup',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                        ],
                        classes: '',
                        id: 'ImportUsersButton',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ImportUsersButtonDescription'),
                        advanceOn: { selector: '#ImportUsersToGroup', event: 'click' },
                    },

                    {

                        attachTo: {
                            element: '#ImportUsersToGroup',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ImportUsersButton',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ImportUsersButtonDescription'),
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },
                    },

                    {

                        attachTo: {
                            element: '#GroupName',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'GroupName',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectGroupToImportDescription'),
                    },
                    {

                        attachTo: {
                            element: '#SelectFileToImport',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'SelectFileToImport',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectFileToImportDescription'),
                    },
                    {

                        attachTo: {
                            element: '#ImportButton',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ImportButton',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ImportButtonDescription'),
                        advanceOn: { selector: '#ImportButton', event: 'click' },
                    },
                    {

                        attachTo: {
                            element: '#DoneButton',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.complete
                        ],
                        classes: '',
                        id: 'DoneButton',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('CorrectOrElseDoneButtonDescription'),
                        advanceOn: { selector: '#DoneButton', event: 'click' },
                    }

                ]);
        }

        else if (this.href.indexOf('sociogramwizard') > 0) {

            this.shepherdService.addSteps(
                [
                    {
                        attachTo: {
                            element: '#SociogramWizard',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.complete
                        ],
                        classes: '',
                        id: 'SociogramWizard',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SociogramWizardDescription') + '<br>' + this.l('SociogramWizardStep1Description') + '<br>' + this.l('SociogramWizardStep2Description') + '<br>' + this.l('SociogramWizardStep3Description') + '<br>' + this.l('SociogramWizardStep4Description') + '<br>' + this.l('SociogramWizardFinnishDescription'),

                    },

                    

                ]);

        }

        else if (this.href.indexOf('input') > 0) {

            this.shepherdService.addSteps(
                [
                    {
                        attachTo: {
                            element: '#selectMeasurement',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'SelectMeasurementInput',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectMeasurementDescriptionInput'),
                        advanceOn: { selector: '#SelectMeasurement', event: 'click' },

                    },

                    {

                        attachTo: {
                            element: '#selectGroup',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'SelectGroupInput',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectGroupDescriptionInput'),
                        advanceOn: { selector: '#SelectGroup', event: 'click' },

                    },
                    {

                        attachTo: {
                            element: '#selectGroup',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'SelectGroupInput',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectGroupDescriptionInput'),
                        when: {
                            show: () => {

                                this.timeoutExtraLong();

                            }

                        },

                    },
                   
                    {

                        attachTo: {
                            element: '#selectIndividual',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'SelectIndividualInput',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectIndividualDescriptionInput'),
                        advanceOn: { selector: '#SelectIndividual', event: 'click' },
                        

                    },

                    {

                        attachTo: {
                            element: '#selectIndividual',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'SelectIndividualInput',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectIndividualDescriptionInput'),
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },


                    },

                    {

                        attachTo: {
                            element: '#ChooseIndividuals',
                            on: 'bottom'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'SaveInput',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectIndividualsAndSave'),
                        advanceOn: { selector: '#ChooseIndividuals', event: 'click' },

                    },
                    {

                        attachTo: {
                            element: '#ReviewInputAndSave',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.complete
                        ],
                        classes: '',
                        id: 'ReviewInputAndSavet',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ReviewInputAndSaveDescription'),
                        advanceOn: { selector: '#ReviewInputAndSave', event: 'click' },

                    },

                ]);

        }

        else if (this.href.indexOf('sociogram') > 0) {

            this.shepherdService.addSteps(
                [
                    {
                        attachTo: {
                            element: '#SelectMeasureMent',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'SelectMeasurement',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ChooseMeasurementDescription'),
                        advanceOn: { selector: '#ChooseMeasurement', event: 'click' },

                    },
                    {

                        attachTo: {
                            element: '#SelectGroup',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'SelectGroup',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectGroupDescription'),
                        advanceOn: { selector: '#ChooseGroup', event: 'click' },

                    },
                    {

                        attachTo: {
                            element: '#SelectQuestion',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'SelectQuestion',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectQuestionDescription'),
                        advanceOn: { selector: '#ChooseQuestion', event: 'click' },

                    },
                    {

                        attachTo: {
                            element: '#SelectQuestion',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'DummySelectQuestion',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectQuestionDescription'),
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },

                    },
                    {

                        attachTo: {
                            element: '#mynetwork',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ShowSociogram',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SociogramDescription'),

                    },
                    {

                        attachTo: {
                            element: '#ShowTable',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ShowTable',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ShowTableDescription'),

                    },
                    {

                        attachTo: {
                            element: '#ShowOnlyMatches',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ShowOnlyMatches',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ShowOnlyMatchesDescription'),

                    },

                    {

                        attachTo: {
                            element: '#showNumberofRelations',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ShowNumberOfRelations',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ShowNumberOfRelationsDescription'),

                    },

                    {

                        attachTo: {
                            element: '#showWarningLonelyItems',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ShowWarningLonelyItems',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('MarkNotSelectedDescription'),

                    },

                    {

                        attachTo: {
                            element: '#Redraw',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'Redraw',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('RedrawDescription'),

                    },

                    {

                        attachTo: {
                            element: '#Print',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'Print',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('PrintDescription'),

                    },

                    {

                        attachTo: {
                            element: '#Delete',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'Delete',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('DeleteDescription'),

                    },

                    {

                        attachTo: {
                            element: '#GoToInput',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'GoToInput',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('GoToInputDescription'),

                    },

                    {

                        attachTo: {
                            element: '#Filter',
                            on: 'right'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                        ],
                        classes: '',
                        id: 'Filter',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('FilterDescription'),
                        advanceOn: { selector: '#Filter', event: 'click' },
                        

                    },
                    {

                        attachTo: {
                            element: '#Filter',
                            on: 'right'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                        ],
                        classes: '',
                        id: 'DummyFilter',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('FilterDescription'),
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },
                    },

                    {

                        attachTo: {
                            element: '#MultiSelectInput',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'MultiSelectInput',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('MultiSelectInputDescription'),

                    },

                    {

                        attachTo: {
                            element: '#showRelatedItemsInFilter',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ShowRelatedItemsInFilter',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ShowRelatedItemsInFilterDescription'),

                    },

                    {

                        attachTo: {
                            element: '#showAllItems',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.back,
                            this.builtInButtons.complete
                        ],
                        classes: '',
                        id: 'ShowAllItems',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ShowAllItemsDescription'),

                    },

                ]);

        }

        else if (this.href.indexOf('measurements/measurements') > 0) {

            this.shepherdService.addSteps(
                [
                    {
                        attachTo: {
                            element: '#createNewMeasurement',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'NewMeasurement',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('CreateNewMeasurementDescription'),
                        advanceOn: { selector: '#createNewMeasurement', event: 'click' },

                    },
                    {
                        attachTo: {
                            element: '#createNewMeasurement',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'NewMeasurement',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('CreateNewMeasurementDescription'),
                        advanceOn: { selector: '#createNewMeasurement', event: 'click' },
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },

                    },

                    {

                        attachTo: {
                            element: '#GroupName',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'ChooseGroupOrClass',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('ChooseGroupOrClassDescription'),

                    },

                    {

                        attachTo: {
                            element: '#MeasurementName',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'MeasurementName',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('MeasurementNameDescription') + '<br>' + this.l('SchoolStart') + '<br>' + this.l('DevelopmentTalks') + '<br>' + this.l('ChristmasBreak') + '<br>' + this.l('PreClassSelection'),

                    },

                    {

                        attachTo: {
                            element: '#MeasurementDescription',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'MeasurementDescription',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('MeasurementDescriptionDescription') + '<br>' + this.l('ShouldBeDoneWithSurvey') + '<br>' + this.l('OnlyYearSeven'),

                    },

                    {

                        attachTo: {
                            element: '#MeasurementDate',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'MeasurementDaten',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('MeasurementDateDescription'),

                    },

                    {

                        attachTo: {
                            element: '#Questions-link',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back
                        ],
                        classes: '',
                        id: 'MeasurementQuestions',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('MeasurementQuestionsDescription'),
                        advanceOn: { selector: '#Questions-link', event: 'click' },

                    },

                    {

                        attachTo: {
                            element: '#QuestionsToChoose',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'MeasurementQuestionsToChoose',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('MeasurementQuestionsToChooseDescriptionAndSave'),
                        advanceOn: { selector: '#SaveMeasurement', event: 'click' },

                    },

                ]);

        }

        else if (this.href.indexOf('placement/roomDesign') > 0) {

            this.shepherdService.addSteps(
                [

                    {
                        attachTo: {
                            element: '#newRoom',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'NewRoom',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('CreateNewRoomDescription'),
                        advanceOn: { selector: '#newRoom', event: 'click' },
                    },
                    {
                        attachTo: {
                            element: '#newRoom',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'NewRoomDummy',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('CreateNewRoomDescription'),
                        advanceOn: { selector: '#newRoom', event: 'click' },
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },
                    },
                    {
                        attachTo: {
                            element: '#NameInput',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'RoomName',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('RoomNameDescription'),
                        advanceOn: { selector: '#ok', event: 'click' },
                    },
                    {

                        attachTo: {
                            element: '#addTeacherPosition',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'addTeacherPosition',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('addTeacherPositionDescription'),

                    },
                    {

                        attachTo: {
                            element: '#addBench',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'addBench',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('addBenchDescription'),

                    },

                    {

                        attachTo: {
                            element: '#delete',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'deleteBenchOrTeacherPosition',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('deleteBenchOrTeacherPositionDescription'),

                    },
                    {

                        attachTo: {
                            element: '#clear',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'clearBenchAndTeacherPositions',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('clearBenchAndTeacherPositionsDescription'),

                    },
                    {

                        attachTo: {
                            element: '#saveRoom',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.back,
                            this.builtInButtons.complete
                        ],
                        classes: '',
                        id: 'saveRoom',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('saveRoomDescription'),
                        advanceOn: { selector: '#saveRoom', event: 'click' },

                    },

                ]);

        }
        else if (this.href.indexOf('placement/placement') > 0) {

            this.shepherdService.addSteps(
                [
                    {
                        attachTo: {
                            element: '#selectRoom',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'SelectRoom',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectRoomDescription'),
                        advanceOn: { selector: '#SelectRoom', event: 'click' },
                    },
                    {
                        attachTo: {
                            element: '#selectRoom',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'SelectRoomDummy',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectRoomDescription'),
                        advanceOn: { selector: '#SelectRoom', event: 'click' },
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },
                    },

                    {
                        attachTo: {
                            element: '#new',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'newPlacement',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('newPlacementDescription'),
                        advanceOn: { selector: '#new', event: 'click' },
                    },
                    {
                        attachTo: {
                            element: '#new',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'newPlacement',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('newPlacementDescription'),
                        advanceOn: { selector: '#new', event: 'click' },
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },
                    },
                    {
                        attachTo: {
                            element: '#SelectGroup',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                        ],
                        classes: '',
                        id: 'SelectGroup',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectGroupAndOkDescription'),
                        advanceOn: { selector: '#ok', event: 'click' },
                    },
                    {
                        attachTo: {
                            element: '#SelectGroup',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                        ],
                        classes: '',
                        id: 'SelectGroup',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SelectGroupAndOkDescription'),
                        advanceOn: { selector: '#ok', event: 'click' },
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },
                    },

                    {

                        attachTo: {
                            element: '#PlacementRulesTab-link',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'PlacementRules',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('PlacementRulesDescription'),
                        advanceOn: { selector: '#PlacementRulesTab-link', event: 'click' },

                    },
                    //{

                    //    attachTo: {
                    //        element: '#PlacementRules',
                    //        on: 'left'
                    //    },
                    //    buttons: [
                    //        this.builtInButtons.cancel,
                    //        this.builtInButtons.back,
                    //        this.builtInButtons.next
                    //    ],
                    //    classes: '',
                    //    id: 'PlacementRules',
                    //    title: this.l('ThisIsHowYouDoit'),
                    //    text: this.l('PlacementRulesDescription'),
                    //    advanceOn: { selector: '#PlacementRules', event: 'click' },

                    //},
                    {

                        attachTo: {
                            element: '#NewPlacementRule',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                        ],
                        classes: '',
                        id: 'NewPlacementRule',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('NewPlacementRuleDescription'),
                        advanceOn: { selector: '#NewPlacementRule', event: 'click' },

                    },
                    {

                        attachTo: {
                            element: '#NewPlacementRule',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                        ],
                        classes: '',
                        id: 'NewPlacementRuleDummy',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('NewPlacementRuleDescription'),
                        advanceOn: { selector: '#NewPlacementRule', event: 'click' },
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },
                    },                 
                    {

                        attachTo: {
                            element: '#PlacementItem',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'PlacementItem',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('PlacementItemDescription'),

                    },
                    {

                        attachTo: {
                            element: '#PlacementRuleType',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'PlacementRuleType',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('PlacementRuleTypeDescription'),

                    },
                    {

                        attachTo: {
                            element: '#RelatedPlacementItems',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                        ],
                        classes: '',
                        id: 'RelatedPlacementItems',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('RelatedPlacementItemsAndOkDescription'),
                        advanceOn: { selector: '#PlacementRuleOk', event: 'click' },
                    },
                    {

                        attachTo: {
                            element: '#RelatedPlacementItems',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back,
                        ],
                        classes: '',
                        id: 'RelatedPlacementItemsDummy',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('RelatedPlacementItemsAndOkDescription'),
                        advanceOn: { selector: '#PlacementRuleOk', event: 'click' },
                        when: {
                            show: () => {

                                this.timeoutLong();

                            }

                        },
                    },
                    {

                        attachTo: {
                            element: '#AllPlacementRules',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'AllPlacementRules',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('AllPlacementRulesDescription'),

                    },
                    {

                        attachTo: {
                            element: '#CalculatePlacement',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.next
                        ],
                        classes: '',
                        id: 'CalculatePlacement',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('CalculatePlacementDescription'),
                        advanceOn: { selector: '#CalculatePlacement', event: 'click' },

                    },
                    {

                        attachTo: {
                            element: '#SavePlacement',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel,
                            this.builtInButtons.back
                        ],
                        classes: '',
                        id: 'SavePlacement',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('SavePlacementDescription'),
                        advanceOn: { selector: '#SavePlacement', event: 'click' },

                    },

                    {

                        attachTo: {
                            element: '#DownloadPicture',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.back,
                            this.builtInButtons.complete
                        ],
                        classes: '',
                        id: 'DownloadPicture',
                        title: this.l('ThisIsHowYouDoit'),
                        text: this.l('DownloadPictureDescription'),
                        advanceOn: { selector: '#DownloadPicture', event: 'click' },

                    },

                ]);

        }

        else {
            this.shepherdService.addSteps(
                [
                    {
                        attachTo: {
                            element: '',
                            on: 'left'
                        },
                        buttons: [
                            this.builtInButtons.cancel
                        ],
                        classes: '',
                        id: 'Q1',
                        title: this.l('NoTourYet'),
                        text: this.l('NoTourYetDescription')
                    }
                ]);


        }

        this.shepherdService.start();

    }

    ngOnInit() {

    
    }
   }
