import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private _featureCheckerService: FeatureCheckerService,
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            //new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            //new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Sociogram', '', 'flaticon-network', '', [
                new AppMenuItem("QuickStart Wizard", "Pages.Sociogram.Wizard", "flaticon2-fast-next", "/app/main/wizard/sociogram", undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("App.Sociogram");
                }),

                new AppMenuItem('Collect', 'Pages.Sociogram.Input', 'flaticon-list', '/app/main/input/sociogram', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("App.Sociogram");
                }),
                new AppMenuItem('PresentResult', 'Pages.Sociogram.Sociogram', 'flaticon-analytics', '/app/main/sociogram', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("App.Sociogram");
                }),

                new AppMenuItem('Measurements', 'Pages.Administration.Measurements', 'flaticon-line-graph', '/app/admin/measurements/sociogram'),
                new AppMenuItem('Questions', 'Pages.Administration.Questions', 'flaticon-questions-circular-button', '/app/admin/questions/sociogram'),
              ]),
                new AppMenuItem('SelfAssessment', '', 'flaticon-tabs', '', [
                    new AppMenuItem("QuickStart Wizard", "Pages.SelfAssessment.Wizard", "flaticon2-fast-next", "/app/main/wizard/selfassessment", undefined, undefined, undefined, () => {
                        return this._featureCheckerService.isEnabled("App.SelfAssessment");
                    }),
    
                    new AppMenuItem('Collect', 'Pages.SelfAssessment.Input', 'flaticon-list', '/app/main/input/selfassessment', undefined, undefined, undefined, () => {
                        return this._featureCheckerService.isEnabled("App.SelfAssessment");
                    }),
                    new AppMenuItem('PresentResult', 'Pages.SelfAssessment.SelfAssessment', 'flaticon-analytics', '/app/main/selfassessment', undefined, undefined, undefined, () => {
                        return this._featureCheckerService.isEnabled("App.SelfAssessment");
                    }),
    
                    new AppMenuItem('Measurements', 'Pages.Administration.Measurements', 'flaticon-line-graph', '/app/admin/measurements/selfassessment'),
                    new AppMenuItem('Questions', 'Pages.Administration.Questions', 'flaticon-questions-circular-button', '/app/admin/questions/selfassessment'),
                    ]),
            new AppMenuItem('ClassRoomPlacement', '', 'flaticon-presentation', '', [

                new AppMenuItem("QuickStart Wizard", "Pages.Placement.Placements", "flaticon2-fast-next", "/app/placement/seatplanwizard", undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("Module.FloorPlanPlacement");
                }),

                new AppMenuItem('RoomDesign', 'Pages.Placement.Places', 'flaticon-squares-3', '/app/placement/roomDesign', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("Module.FloorPlanPlacement");
                }), //fa fa-door-open
                new AppMenuItem('Placement', 'Pages.Placement.Placements', 'flaticon-presentation', '/app/placement/placement', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("Module.FloorPlanPlacement");
                }),
            ]),
            new AppMenuItem('Estimation', '', 'flaticon-analytics', '', [
                new AppMenuItem("QuickStart Wizard", "Pages.Estimation.Wizard", "flaticon2-fast-next", "/app/estimation/estimationwizard", undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("Module.Estimation");
                }),
                new AppMenuItem('Collect', 'Pages.Estimation.Input', 'flaticon-squares-3', '/app/estimation/estimationinput', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("Module.Estimation");
                }), //fa fa-door-open
                new AppMenuItem('PresentResult', 'Pages.Estimation.Placements', 'flaticon-presentation', '/app/estimation/estimationinput', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("Module.Estimation");
                }),

                ]),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("App.ModifyRoles");
                }),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
 

                new AppMenuItem('MeasurementQuestions', 'Pages.Administration.MeasurementQuestions', 'flaticon-arrows', '/app/admin/measurements/measurementQuestions'),

                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("App.ModifyLanguage");
                }),
                new AppMenuItem('Languages', 'Pages.Administration.Host.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("App.SelectTheme");
                }),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings', undefined, undefined, undefined, () => {
                    return this._featureCheckerService.isEnabled("App.ModifyTenantSettings");
                }),
                new AppMenuItem('Agreements', 'Pages.Administration.Host.Maintenance', 'flaticon-file', '/app/admin/agreements'),
            ]),
            //new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
