import { Component, Injector, ViewEncapsulation, ElementRef, HostBinding, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    templateUrl: './welcome.component.html',
    selector: 'welcome',
    styleUrls: ['./welcome.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class WelcomeComponent extends AppComponentBase implements OnInit {

    currentUser = '';

    offcanvasOptions: OffcanvasOptions = {
        overlay: true,
        baseClass: 'kt-demo-panel',
        closeBy: 'kt-theme_selection_panel_close',
        toggleBy: 'kt_theme_selection_panel_toggle'
    };

    constructor(
        injector: Injector,
        private _uiCustomizationService: UserServiceProxy) {
        super(injector);
    }

    ngOnInit() {
        this.currentUser = 'Kalle Anka';
    }



}
