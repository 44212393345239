import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Observable } from '@node_modules/rxjs/internal/Observable';
import { InputPwdLessComponent } from '@app/pwdless/input_pwdless/input_pwdless.component';
import { NotifyService } from '@abp/notify/notify.service';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad, CanDeactivate<any> {

    constructor(
        private _notifyService : NotifyService,
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService
    ) { }

    isPwdLess: boolean = false;


    canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
        let result = this.canDeActivateInternal();
        if(!result)
            this._notifyService.warn('Please login with password');
        return result;
}

   
  canDeActivateInternal(): boolean {
    return !this.isPwdLess;
  }

    canActivateInternal(data: any, state: RouterStateSnapshot): boolean {
        if (UrlHelper.isInstallUrl(location.href)) {
            return true;
        }

        if( location.search.startsWith("?u=")){
            this.isPwdLess = true;
            return true;
        }
        
        
        
        // if(this.isPwdLess && !location.href.includes("pwdless")){
        //     this._router.navigate(['/account/login']);
        //     return false;
        // }

        if (!this._sessionService.user && !(location.href.includes("pwdless"))) {
            this._router.navigate(['/account/login']);
            return false;
        }

        if (!data || !data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(data['permission'])) {
            return true;
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    selectBestRoute(): string {

        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
            return '/app/admin/hostDashboard';
        }

        //if (this._permissionChecker.isGranted('Pages.Tenant.Dashboard')) {
        //    return '/app/main/dashboard';
        //}

        //if (this._permissionChecker.isGranted('Pages.Tenants')) {
        //    return '/app/admin/tenants';
        //}

        //if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
        //    return '/app/admin/users';
        //}

        //return '/app/notifications';
        return '/app/welcome';
    }
}
